import { FunctionComponent } from "react";
import { SingleValue } from "react-select";
import {
  ISelectOption,
  UserManagmentItem,
  UserRight,
  userRights,
} from "../types/apiTypes";
import { rightsMapping } from "../utils";
import SelectBox from "./ui/SelectBox";

interface Props {
  item: UserManagmentItem;
  onChange: (value: UserRight) => void;
}

const isUserRight = (value: string): value is UserRight =>
  userRights.includes(value as UserRight);

const RightsSelect: FunctionComponent<Props> = ({ item, onChange }) => {
  const options: ISelectOption[] = Array.from(rightsMapping).reduce(
    (obj: ISelectOption[], [key, value]) => {
      const isDisabled =
        (item.person?.personId === undefined && key === "member") ||
        (item.person?.personId !== undefined && key !== "member");

      const result: ISelectOption = {
        value: key,
        label: value,
        isDisabled: isDisabled,
      };

      return [result, ...obj];
    },
    []
  );

  const updateRight = (value: SingleValue<ISelectOption>) => {
    const option = options.filter((x) => x.value === value?.value)[0];
    option?.value && isUserRight(option.value) && onChange(option.value);
  };

  return (
    <SelectBox
      value={{
        value: item.permission,
        label: rightsMapping.get(item.permission) ?? "",
      }}
      placeholder="Rolle wählen"
      onChange={updateRight}
      options={options}
    />
  );
};

export default RightsSelect;
