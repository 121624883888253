import { Fragment, FunctionComponent, useEffect, useState } from "react";

interface Props {
  text: string;
  highlight: string;
}

const HighlightText: FunctionComponent<Props> = ({ text, highlight }) => {
  const [parts, setParts] = useState<string[]>([]);

  useEffect(
    () => setParts(text.split(new RegExp(`(${highlight})`, "gi"))),
    [text, highlight]
  );

  return (
    <span>
      {parts.map((part, index) => (
        <Fragment key={index}>
          {part.toLowerCase() === highlight.toLowerCase() ? (
            <strong>{part}</strong>
          ) : (
            part
          )}
        </Fragment>
      ))}
    </span>
  );
};

export default HighlightText;
