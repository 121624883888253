import classNames from "classnames";
import { FunctionComponent } from "react";
import { Glyph } from "../../types/types";
import styles from "./Button.module.css";
import Icon from "./Icon";

interface Props {
  url: string;
  glyph?: Glyph;
  className?: string;
  newTab?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
}

const Link: FunctionComponent<Props> = ({
  url,
  glyph,
  className,
  newTab = false,
  fullWidth = false,
  children,
}) => (
  <a
    href={url}
    className={classNames(
      styles.btn,
      { [styles.fullWidth]: fullWidth },
      className
    )}
    {...(newTab && { target: "_blank" })}
  >
    {glyph && <Icon className={styles["btn-icon"]} glyph={glyph} />}
    {children}
  </a>
);

export default Link;
