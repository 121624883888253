import { FunctionComponent, useContext, useEffect, useState } from "react";
import { getLogosList, getTemplatesList } from "../http/chapterApi";
import { Chapter } from "../types/apiTypes";
import { DataContext } from "./AppRouter";
import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import Loading from "./ui/Loading";

interface Props {
  isTemplates?: boolean;
}

const filterTemplates = (
  templates: string[] | undefined,
  chapter: Chapter | undefined
) =>
  templates?.filter(
    (template) =>
      !(
        template === "Einzahlung_Mehrwertsteuer_Vorlage.xltx" &&
        !chapter?.vatNumber
      )
  );

const List: FunctionComponent<Props> = ({ isTemplates }) => {
  const [files, setFiles] = useState<string[]>();
  const { chapter } = useContext(DataContext);

  useEffect(() => {
    const fetchResponse = async (isTemplates: boolean | undefined) => {
      const response = isTemplates
        ? await getTemplatesList()
        : await getLogosList(chapter);

      const filterResponse = isTemplates
        ? filterTemplates(response, chapter)
        : response;

      setTimeout(() => setFiles(filterResponse), 350);
    };

    fetchResponse(isTemplates);
  }, [chapter]);

  return (
    <>
      <ListHeader items={["NAME", "DATEITYP", undefined]} />
      {!files ? (
        <Loading />
      ) : !files?.length ? (
        <p>Keine {isTemplates ? "Vorlagen" : "Logos"} vorhanden</p>
      ) : (
        files.map((item, index) => (
          <ListItem file={item} key={index} isTemplates={isTemplates} />
        ))
      )}
    </>
  );
};

export default List;
