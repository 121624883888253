import { FunctionComponent, useEffect, useState } from "react";
import SVGMenu from "../icons/Menu.svg?react";
import AccountButton from "./AccountButton";
import ChapterField from "./ChapterField";
import styles from "./Header.module.css";
import Menu from "./Menu";
import Icon from "./ui/Icon";

const Header: FunctionComponent = () => {
  const minWidthMenuAlwaysOpen = 1400; // px

  const [isMenuOpen, setMenuOpen] = useState(
    window.innerWidth > minWidthMenuAlwaysOpen
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setMenuOpen(window.innerWidth > minWidthMenuAlwaysOpen);
    });
  });

  return (
    <div>
      <header className={styles.header}>
        <div className={styles["header-left"]}>
          <button
            className={styles.hamburger}
            onClick={() => setMenuOpen(true)}
          >
            <Icon glyph={SVGMenu} />
          </button>
          <ChapterField />
        </div>
        <AccountButton />
      </header>
      {isMenuOpen && (
        <div
          className={styles["menu-background"]}
          onClick={() => setMenuOpen(false)}
        />
      )}
      <Menu isOpen={isMenuOpen} setOpen={setMenuOpen} />
    </div>
  );
};

export default Header;
