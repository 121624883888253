import { FunctionComponent } from "react";
import { UserManagmentItem, UserPermission } from "../../types/apiTypes";
import RightsSelect from "../RightsSelect";
import FunctionarySelect from "./FunctionarySelect";
import UserManagmentDeleteButton from "./UserManagmentDeleteButton";
import styles from "./UserManagmentList.module.css";
import UserManagmentSaveButton from "./UserManagmentSaveButton";
import UserSelect from "./UserSelect";

interface Props {
  newUsers: UserManagmentItem[];
  setNewUsers: React.Dispatch<React.SetStateAction<UserManagmentItem[]>>;
  selectedUsers: UserManagmentItem[];
  accountPermission: UserPermission;
  loadUsers: () => void;
}

const NewUsers: FunctionComponent<Props> = ({
  newUsers,
  setNewUsers,
  selectedUsers,
  accountPermission,
  loadUsers,
}) => (
  <>
    {newUsers.map((item, index) => (
      <div className={styles.row} key={`new_users_${index}`}>
        <UserSelect
          onChange={(value) => {
            const item = newUsers[index];
            if (item) {
              const newItems = [...newUsers];
              const newItem: UserManagmentItem = {
                ...item,
                iamUser: value,
              };
              newItems[index] = newItem;
              setNewUsers(newItems);
            }
          }}
          item={item}
        />
        <FunctionarySelect
          item={item}
          onChange={(value) => {
            const item = newUsers[index];
            if (item) {
              const newItems = [...newUsers];
              const newItem: UserManagmentItem = {
                ...item,
                person: value ?? null,
              };
              newItems[index] = newItem;
              setNewUsers(newItems);
            }
          }}
        />
        <RightsSelect
          item={item}
          onChange={(value) => {
            const item = newUsers[index];
            if (item) {
              const newItems = [...newUsers];
              const newItem: UserManagmentItem = {
                ...item,
                permission: value,
              };
              newItems[index] = newItem;
              setNewUsers(newItems);
            }
          }}
        />
        <UserManagmentDeleteButton
          item={item}
          selectedItems={selectedUsers}
          accountPermission={accountPermission}
          deleteUser={(user: UserManagmentItem) => {
            setNewUsers((prevstate) =>
              prevstate.filter((x) => x !== user).map((x) => ({ ...x }))
            );
          }}
        />
        <UserManagmentSaveButton
          item={item}
          users={selectedUsers}
          accountPermission={accountPermission}
          onSave={() => {
            loadUsers();
            const newItems = newUsers.filter((x) => x !== item);
            setNewUsers(newItems);
          }}
        />
      </div>
    ))}
  </>
);

export default NewUsers;
