import { FunctionComponent } from "react";
import { useAuth } from "../../../context/authContext";
import Button from "../ui/Button";
import styles from "./NotAuthorized.module.css";

const NotAuthorized: FunctionComponent = () => {
  const { logout } = useAuth();

  return (
    <div className={styles["not-authorized"]}>
      <h1 className={styles.title}>Es ist ein Fehler aufgetreten!</h1>
      <p className={styles.text}>
        Es sieht so aus, als hätten Sie keine Berechtigung auf diese Applikation
        zuzugreifen.
      </p>
      <p className={styles["text-small"]}>
        Für weitere Informationen melden Sie sich bitte beim:
      </p>
      <h2 className={styles.subtitle}>HGV</h2>
      <p className={styles["text-small"]}>
        <strong>Ehrenamtsmanagement</strong>
        <br />
        Büro Bozen, Schlachthofstraße 59, 39100 Bozen
      </p>
      <p className={styles["text-small"]}>
        <span className={styles["link-container"]}>
          Tel:{" "}
          <a href="tel:+39 0471 317 700" className={styles.link}>
            0471 317 700
          </a>
        </span>
        <span className={styles["link-container"]}>
          E-Mail:{" "}
          <a href="mailto:info@hgv.it" className={styles.link}>
            info@hgv.it
          </a>
        </span>
      </p>
      <Button
        buttonProps={{
          onClick: () => {
            logout();
          },
          className: styles.button,
        }}
      >
        Abmelden
      </Button>
    </div>
  );
};

export default NotAuthorized;
