import { Session } from "@ory/client-fetch";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { isEmployee, useAuth } from "../../context/authContext";
import {
  EmailIdentity,
  User,
  UserIdentity,
  isEmailIdentity,
  isUserIdentity,
} from "../../context/types";
import SVGPerson from "../icons/Person.svg?react";
import styles from "./AccountButton.module.css";
import Icon from "./ui/Icon";

const getUserFromUserIdentity = (i: UserIdentity): User => {
  return {
    email: i.traits.email,
    name: i.traits.name,
    profilePhoto: i.metadata_public?.profile_photo,
  };
};

const getUserFromEmailIdentity = (i: EmailIdentity): User => {
  return {
    email: i.traits.email,
    name: i.traits.email,
  };
};

const getUserFromSession = (session: Session): User => {
  const identity = session.identity;
  if (!identity) {
    throw new Error("no identity found");
  }

  if (isUserIdentity(identity)) return getUserFromUserIdentity(identity);
  else if (isEmailIdentity(identity)) return getUserFromEmailIdentity(identity);

  throw new Error(`unhandled schema '${identity.schema_id}'`);
};

const AccountButton: FunctionComponent = () => {
  const auth = useAuth();
  const [menuShown, setMenuShown] = useState(false);

  const [user, setUser] = useState<User>();

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleMenu = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      ref.current && !ref.current.contains(target) && setMenuShown(false);
    };

    menuShown && document.addEventListener("click", handleMenu);
    return () => document.addEventListener("click", handleMenu);
  }, [menuShown]);

  useEffect(() => {
    setUser(auth.session ? getUserFromSession(auth.session) : undefined);
  }, [auth.session]);

  if (!user) {
    return;
  }

  return (
    <>
      <button
        ref={ref}
        className={styles.btn}
        onClick={() => setMenuShown(!menuShown)}
      >
        <div className={styles["user-info"]}>
          <strong title={user.name}>{user.name}</strong>
          <div className={styles.email}>
            <span title={user.email}>{user.email}</span>
          </div>
        </div>
        {isEmployee(user!.email) && user.profilePhoto ? (
          <div className={styles["user-icon"]}>
            <img src={user.profilePhoto} className={styles.photo} />
          </div>
        ) : (
          <div className={styles["user-icon"]}>
            <Icon glyph={SVGPerson} className={styles["placeholder-icon"]} />
          </div>
        )}
      </button>
      {menuShown && (
        <ul className={styles.menu}>
          {false && !isEmployee(user!.email) && (
            <li onClick={auth.resetPassword}>
              <a href="#">Passwort ändern</a>
            </li>
          )}
          <li onClick={auth.logout}>
            <a href="#">Abmelden</a>
          </li>
        </ul>
      )}
    </>
  );
};

export default AccountButton;
