import classNames from "classnames";
import { useCombobox } from "downshift";
import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Chapter } from "../types/apiTypes";
import { DataContext } from "./AppRouter";
import styles from "./ChapterSelection.module.css";
import HighlightText from "./HighlightText";

import SVGArrowDown from "../icons/ArrowDown.svg?react";
import Icon from "./ui/Icon";
import SearchInput from "./ui/SearchInput";

interface Props {
  chapterList: Chapter[] | undefined;
}

const ChapterSelection: FunctionComponent<Props> = ({ chapterList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputItems, setInputItems] = useState<Chapter[]>();
  const inputRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const { chapter, setChapter } = useContext(DataContext);

  useEffect(() => {
    isOpen && inputRef.current?.focus();
  }, [isOpen]);

  document.addEventListener("mousedown", (e: Event) => {
    !searchRef.current?.contains(e.target as Node) && setIsOpen(false);
  });

  const {
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    inputValue,
    setInputValue,
  } = useCombobox({
    defaultIsOpen: true,
    items: inputItems ?? [],
    itemToString: (item) => item?.name ?? "",
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItem && setChapter(selectedItem);
      localStorage.setItem("chapter", JSON.stringify(selectedItem?.id));
      setIsOpen(false);
      setInputValue("");
    },
    onInputValueChange: ({ inputValue }) =>
      setInputItems(!inputValue ? chapterList : filterByInput(inputValue)),
  });

  const filterByInput = (value: string) =>
    chapterList?.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

  const indexOfItem = (item: string): number =>
    (inputItems ?? []).findIndex((input) => input.name === item);

  const isItemHighlighted = (item: string): boolean =>
    highlightedIndex === indexOfItem(item);

  return (
    <div className={styles.search} ref={searchRef}>
      <div className={styles.combobox}>
        <p className={styles.label}>HGV Ortsgruppe:</p>
        <button
          className={styles.btn}
          onClick={() => {
            setIsOpen(!isOpen);
            setInputItems(chapterList);
            setInputValue("");
            inputRef.current?.focus();
          }}
        >
          <strong>{chapter?.name}</strong>
          <Icon
            glyph={SVGArrowDown}
            className={classNames(styles["arrow-icon"], {
              [styles["rotate-arrow"]]: isOpen,
            })}
          />
        </button>
      </div>
      <div
        className={classNames(styles.suggestions, {
          [styles["suggestions-shown"]]: isOpen,
        })}
        {...getMenuProps()}
      >
        <div className={styles["search-wrapper"]}>
          <SearchInput
            inputValue={inputValue}
            setInputValue={setInputValue}
            fullWidth={true}
            inputObject={getInputProps({ ref: inputRef })}
          />
        </div>
        <dl className={styles["suggestion-list"]}>
          {isOpen && (
            <>
              {inputValue.length !== 0 && !inputItems?.length ? (
                <p className={styles["empty-text"]}>
                  Es wurden keine mit deiner Suchanfrage -{" "}
                  <strong>{inputValue}</strong> - übereinstimmende Ergebnisse
                  gefunden.
                </p>
              ) : (
                inputItems?.map((item, index) => (
                  <dd
                    {...getItemProps({ item, index })}
                    className={classNames(styles.suggestion, {
                      [styles["highlighted-suggestion"]]: isItemHighlighted(
                        item.name
                      ),
                    })}
                    key={index}
                  >
                    {inputValue.length ? (
                      <HighlightText text={item.name} highlight={inputValue} />
                    ) : (
                      item.name
                    )}
                  </dd>
                ))
              )}
            </>
          )}
        </dl>
      </div>
    </div>
  );
};

export default ChapterSelection;
