import { FunctionComponent, useContext, useEffect, useState } from "react";
import { getCommitteeExport, getMemberExport } from "../http/chapterApi";
import Download from "../icons/Download.svg?react";
import SVGLetter from "../icons/Letter.svg?react";
import { Chapter, Person } from "../types/apiTypes";
import { DataContext } from "./AppRouter";
import styles from "./PersonActions.module.css";
import Button from "./ui/Button";
import Icon from "./ui/Icon";
import Link from "./ui/Link";
import SearchInput from "./ui/SearchInput";

interface Props {
  items: Person[];
  inputValue: string;
  setInputValue: (value: string) => void;
  isCommittee: boolean;
}

const getEmailString = (items: Person[]) =>
  items.map((item) => item.email && `${item.email};`).join("");

const downloadFile = async (
  chapter: Chapter | undefined,
  isCommittee: boolean
) => {
  if (!chapter) return;
  const blob = isCommittee
    ? await getCommitteeExport(chapter.id)
    : await getMemberExport(chapter.id);
  const url = blob && window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url ?? "";
  const name = chapter.name.split(" ").join("-").toLowerCase();
  link.setAttribute(
    "download",
    `${name}-${isCommittee ? "ausschussmitglieder" : "mitglieder"}.csv`
  );
  document.body.appendChild(link);
  link.click();
};

const PersonActions: FunctionComponent<Props> = ({
  items,
  inputValue,
  setInputValue,
  isCommittee,
}) => {
  const emails = getEmailString(items);
  const [showMessage, setShowMessage] = useState(false);
  const { chapter } = useContext(DataContext);

  const label =
    items.length > 1
      ? `E-Mail an alle ${items.length} ${
          isCommittee ? "Ausschussmitglieder" : "Mitglieder"
        } senden`
      : `E-Mail an ${items.length} ${
          isCommittee ? "Ausschussmitglied" : "Mitglied"
        } senden`;

  useEffect(() => {
    showMessage &&
      setTimeout(() => {
        setShowMessage(false);
      }, 7000);
  }, [showMessage]);

  return (
    <div className={styles.row}>
      <SearchInput inputValue={inputValue} setInputValue={setInputValue} />
      {!!items.length && (
        <>
          {emails.length >= 2000 ? (
            <Button
              buttonProps={{
                onClick: () => {
                  navigator.clipboard.writeText(emails);
                  console.error(
                    "E-Mail provider can not be opened: Too much emails, but copied to the clipboard."
                  );
                  setShowMessage(true);
                },
              }}
              activeColor={showMessage}
              fullWidth={true}
            >
              <Icon glyph={SVGLetter} />
              {showMessage ? "E-Mails in Zwischenablage kopiert!" : label}
            </Button>
          ) : (
            <Link
              url={isCommittee ? `mailto:${emails}` : `mailto:?bcc=${emails}`}
              fullWidth={true}
            >
              <Icon glyph={SVGLetter} />
              {label}
            </Link>
          )}
        </>
      )}
      <Button
        buttonProps={{
          onClick: () => {
            downloadFile(chapter, isCommittee);
          },
        }}
        fullWidth={true}
      >
        <Icon glyph={Download} />
        Kontakte als Liste herunterladen
      </Button>
    </div>
  );
};

export default PersonActions;
