import classNames from "classnames";
import { addMonths, format, isSameDay, isSameMonth, subMonths } from "date-fns";
import { de } from "date-fns/locale";
import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { DayPicker } from "react-day-picker";
import { getEvents } from "../../http/chapterApi";
import SVGMarker from "../../icons/Marker.svg?react";
import { EventType, ModalType } from "../../types/apiTypes";
import { LastViewedObject } from "../../types/types";
import { DataContext } from "../AppRouter";
import Page from "../Page";
import useFetchPage from "../hooks/useFetchPage";
import Icon from "../ui/Icon";
import Link from "../ui/Link";
import Modal from "../ui/Modal";
import styles from "./Calendar.module.css";

interface Props {
  setLastViewed: (value: LastViewedObject) => void;
}

const Calendar: FunctionComponent<Props> = ({ setLastViewed }) => {
  const page = useFetchPage(setLastViewed);
  const [events, setEvents] = useState<EventType[]>();
  const [activeDay, setActiveDay] = useState(new Date());
  const [month, setMonth] = useState(new Date());
  const [modal, setModal] = useState<ModalType>({
    isOpen: false,
    data: undefined,
  });
  const { permissions } = useContext(DataContext);

  useEffect(() => {
    if (!permissions?.userId) return;
    (async () =>
      setEvents(
        await getEvents(
          permissions.userId,
          month.getFullYear(),
          month.getMonth() + 1
        )
      ))();
  }, [month]);

  const monthNavigation = (date: Date) => {
    const newMonth = isSameMonth(date, subMonths(month, 1))
      ? subMonths(month, 1)
      : addMonths(month, 1);

    setMonth(newMonth);
  };

  return (
    <>
      {page && (
        <Page title={page.frontmatter.title} description={page.content}>
          <DayPicker
            mode="single"
            locale={de}
            month={month}
            onMonthChange={setMonth}
            components={{
              Day: (props) => {
                const dayEvents = events?.filter(
                  (event) =>
                    isSameDay(new Date(event.from), props.date) &&
                    isSameMonth(props.date, month)
                );

                const isOtherMonth = !isSameMonth(props.date, month);
                const isDayActive =
                  isSameDay(props.date, new Date()) ||
                  isSameDay(props.date, activeDay);

                return (
                  <button
                    className={classNames(
                      styles["daypicker-day-button"],
                      { [styles["daypicker-day-outside"]]: isOtherMonth },
                      { [styles["daypicker-day-active"]]: isDayActive },
                      { [styles["daypicker-day-event"]]: dayEvents?.length }
                    )}
                    onClick={() => {
                      isOtherMonth && monthNavigation(props.date);
                      dayEvents?.length &&
                        setModal({ isOpen: !modal.isOpen, data: dayEvents });
                      setActiveDay(props.date);
                    }}
                  >
                    {props.date.getDate()}
                  </button>
                );
              },
            }}
            showOutsideDays={true}
            classNames={{
              root: styles["daypicker"],
              months: styles["daypicker-months"],
              caption: styles["daypicker-caption"],
              caption_label: styles["daypicker-caption-title"],
              table: styles["daypicker-table"],
              head_cell: styles["daypicker-header-cell"],
              cell: styles["daypicker-cell"],
              nav: styles["daypicker-nav"],
              nav_button: styles["daypicker-nav-button"],
              nav_icon: styles["daypicker-nav-icon"],
            }}
          />
          {modal.isOpen && modal.data && (
            <CalendarModal
              data={modal.data}
              onClose={() => setModal({ isOpen: false, data: undefined })}
            />
          )}
        </Page>
      )}
    </>
  );
};

const LocationText: FunctionComponent<{ text: string }> = ({ text }) => {
  const splitted = text.split(",");

  return (
    <p className={styles["location-text"]}>
      <span className={styles["location-underline"]}>
        {splitted.map((item, index) => (
          <Fragment key={index}>
            {!index ? <strong>{item}</strong> : <>{item}</>}
            {splitted.length > index + 1 && ", "}
          </Fragment>
        ))}
      </span>
    </p>
  );
};

interface CalendarModalType {
  data: EventType[];
  onClose: () => void;
}

const CalendarModal: FunctionComponent<CalendarModalType> = ({
  data,
  onClose,
}) => (
  <Modal onClose={onClose}>
    <>
      {data.map((e) => (
        <div className={styles.event} key={e.id}>
          <h3 className={styles.title}>{e.title}</h3>
          <p className={styles.subtitle}>{e.category}</p>
          {e.description && <p className={styles.text}>{e.description}</p>}
          <div className={styles.bar}>
            <div className={styles.location}>
              <Icon glyph={SVGMarker} className={styles["location-icon"]} />
              <div>
                {e.location && <LocationText text={e.location} />}
                <p className={styles["location-text"]}>
                  <strong>{format(new Date(e.from), "dd.MM.yyyy")},</strong>{" "}
                  {format(new Date(e.from), "HH:mm")} -{" "}
                  {format(new Date(e.to), "HH:mm")} Uhr
                </p>
              </div>
            </div>
            {e.signUpLink && (
              <Link url={e.signUpLink} className={styles.btn} newTab={true}>
                Anmelden
              </Link>
            )}
          </div>
        </div>
      ))}
    </>
  </Modal>
);

export default Calendar;
