import classNames from "classnames";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { getDistrictsCommitteeList } from "../../http/chapterApi";
import { cmsUrl } from "../../http/cmsApi";
import { Chapter, DistrictMember, Person } from "../../types/apiTypes";
import { Contact, Frontmatter } from "../../types/types";
import { DataContext } from "../AppRouter";
import Box from "../Box";
import styles from "../Boxes.module.css";
import PersonBox from "../PersonBox";
import Loading from "../ui/Loading";

interface Props {
  frontmatter: Frontmatter;
}

const getTypedPersons = (persons: DistrictMember[]): Person[] =>
  persons.map((item) => ({
    id: String(item.id),
    picture: item.person.picture,
    name: item.person.firstName,
    lastName: item.person.lastName,
    email: item.person.email,
    mobile: item.person.mobile,
    street: item.person.street,
    zip: item.person.zip,
    city: item.person.city,
    companyName: item.company,
    roles: item.roles,
  }));

const ContactBoxes: FunctionComponent<Props> = ({ frontmatter }) => {
  const [persons, setPersons] = useState<Person[]>();
  const [loading, setLoading] = useState(true);
  const { chapter } = useContext(DataContext);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedCommittee =
        chapter && (await getDistrictsCommitteeList(chapter.area.district.id));

      const sortedCommittee = fetchedCommittee?.sort((a, b) => {
        const aNum = a.isPresident
          ? 1
          : a.isDirector
          ? 2
          : a.isDistrictChairman
          ? 3
          : a.isAreaChairman
          ? 4
          : 5;
        const bNum = b.isPresident
          ? 1
          : b.isDirector
          ? 2
          : b.isDistrictChairman
          ? 3
          : b.isAreaChairman
          ? 4
          : 5;

        return aNum - bNum;
      });

      const persons = sortedCommittee && getTypedPersons(sortedCommittee);

      setPersons(persons ?? []);
      setTimeout(() => setLoading(false), 350);
    };

    setLoading(true);
    fetchData();
  }, [chapter]);

  return !persons || loading ? (
    <Loading />
  ) : (
    <>
      <div>
        {frontmatter.functionary_title && (
          <h2 className={styles["contact-subtitle"]}>
            {frontmatter.functionary_title}
          </h2>
        )}
        <div className={styles.boxes}>
          {persons.map((item, index) => (
            <PersonBox item={item} showImage={true} key={index} />
          ))}
        </div>
      </div>
      <Employees
        title={frontmatter.association_secretary_title}
        items={frontmatter.association_secretary_items}
        chapter={chapter}
      />
      <Employees
        title={frontmatter.member_supervisor_title}
        items={frontmatter.member_supervisor_items}
        chapter={chapter}
      />
    </>
  );
};

const filterByDistrict = (items: Contact[], chapter: Chapter | undefined) =>
  chapter &&
  items
    .filter(
      (item) =>
        !item.district ||
        item.district
          .toLowerCase()
          .includes(chapter.area.district.name.toLowerCase())
    )
    .sort((a, b) => (a.position > b.position ? -1 : 1));

interface EmployeeProps {
  title: string;
  items: Contact[];
  chapter: Chapter | undefined;
}

const Employees = ({ title, items, chapter }: EmployeeProps) => {
  const districtItems = filterByDistrict(items, chapter);

  return (
    <>
      <div className={styles["boxes-separator-container"]}>
        <hr className={styles["boxes-separator"]} />
      </div>
      <div>
        {title && <h2 className={styles["contact-subtitle"]}>{title}</h2>}
        <div className={classNames(styles.boxes, styles["contact-boxes"])}>
          {districtItems?.map((item, index) => (
            <Box
              img={item.photo}
              placeholder={true}
              url={`${cmsUrl}${location.pathname}`}
              key={index}
            >
              <div className={styles["content-fit"]}>
                <h3 className={styles.name}>{item.name}</h3>
                <p className={styles.text}>{item.position}</p>
                <p className={styles.text}>
                  {item.district
                    ? `HGV Bezirk ${item.district.split(",").join(", ")}`
                    : "HGV - Hoteliers- und Gastwirteverband"}
                </p>
                <p className={styles.text}>
                  <a href={`mailto:${item.email}`} className={styles.email}>
                    {item.email}
                  </a>
                </p>
                {item.office.map((office) => (
                  <div className={styles["office-container"]} key={office.name}>
                    <p className={styles.text}>
                      <strong>{office.name}</strong>
                    </p>
                    <p className={styles.text}>{office.address}</p>
                    <p className={styles.text}>
                      <a
                        href={`tel:+39${office.phone.replace(/ /g, "")}`}
                        className={styles.phone}
                      >
                        Tel. +39 {office.phone}
                      </a>
                    </p>
                    {office.fax && (
                      <p className={styles.text}>Fax. +39 {office.fax}</p>
                    )}
                  </div>
                ))}
              </div>
            </Box>
          ))}
        </div>
      </div>
    </>
  );
};

export default ContactBoxes;
